import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { PhoneForwarded } from "lucide-react";
const MobileProgramFooter = ({
  categorizedPrograms,
  category,
  index,
  matchingContact,
}) => {
  return (
    <>
      <Accordion
        key={index}
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          width: "100%",
          "&&:before": {
            borderBottom: "none",
            backgroundColor: "transparent",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: "#fff" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              color: "#fff",
              textDecoration: "none",
              marginBottom: "0.2rem!important",
              fontSize: "17px",
              fontWeight: "bold",
              textTransform:
                category.toLowerCase() === "cfo" ? "uppercase" : "capitalize",
            }}
          >
            {category.replace(/(\bai\b)/gi, "AI")}
            <div className="text-[12px] ">
              ( <PhoneForwarded size={12} className="inline mr-2" />
                <a
                href={`tel:${matchingContact.contact}`}
                className="pl-[1px] pr-[1px] inline text-[#fff]"
                style={{ textDecoration: "none" }}
              >
                {matchingContact.contact} )
              </a>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, ml: 1 }}>
          <ul className="footer-body-content">
            {categorizedPrograms &&
              categorizedPrograms[category].map((program, innerIndex) => (
                <li key={`${program.id}-${innerIndex}`}>
                  <Link
                    to={`/programs/${program.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        textDecoration: "none",
                        marginBottom: "0rem!important",
                        fontSize: "14px",
                      }}
                    >
                      {program.university_name} - {program.program_name}
                    </Typography>
                  </Link>
                </li>
              ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default MobileProgramFooter;